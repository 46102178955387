import * as React from 'react';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { background, logo, logoEn, logo_yellow } from "../../assets/Media";
import "./custom.css";
import { loginUser, loginUserCorporativo } from '../../store/sagas/Auth';
import { useForm } from '../../hooks/useForm';
import { StoreContext } from '../../store/StoreProvider';
import { types } from '../../store/Auth';
import { useHistory } from 'react-router';
import AppConfig from '../../constants/AppConfig';
import { Alert } from "../Alert";
// import { types as typesWarranty } from "../../store/Warranty";
const { types: { seller, partner, sellerApp, partnerApp, corpApp }, url: { seller: seller_url, partner: partner_url } } = AppConfig

interface LoginProps { }

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        Gardi ENERJET
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const Login = (props: LoginProps) => {
  const classes = useStyles();
  const history = useHistory();
  const AlertRef = React.useRef<any>();
  const [store, dispatch] = React.useContext(StoreContext);

  const path =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ].toLocaleLowerCase()

  let type = "";

  [seller_url, partner_url].forEach((item: string, index: number) => {
    if (item === path) {
      type = [seller, partner][index];
    }
  });

  const currentUrl: string = window.location.href;

  const parts: string[] = currentUrl.split('.');
  const tld: string = parts[parts.length - 1];

  const countryEn: boolean = (tld === 'tt/');

  // process.env.REACT_APP_USER_CORP

  const user_data = {
    password: "",
    usuario: "",
    tipoUsuario: "RV",//SN - RV
    nombreApp: corpApp,
  };

  /*
  user_data = {
    imei: "",
    password: "",
    ruc: "",
    // tipo: "RV",
    tipo: store?.user?.tipo || type || partner,
    App: (store?.user?.tipo === seller || type === seller) ? sellerApp : partnerApp,
  };
  */

  const [values, handleChage] = useForm(user_data)
  const { user } = store

  React.useEffect(() => {
    if (!!store?.user?.auth) {
      history.push({ pathname: "/" });
    }
  }, [user])

  const send = (e: any) => {
    e.preventDefault();

    if (process.env.REACT_APP_USER_CORP) {
      loginUserCorporativo(values).then((data) => {

        if (data?.statusCode === 200) {
          if (data.size === 0) {
            AlertRef?.current?.open(/* data.message ?? */countryEn ? 'User not found' : 'Usuario no encontrado', 'error')
            return;
          }

          dispatch({ type: types.authLogin, payload: data?.value });
          history.push({ pathname: "/home" });

        } else {
          AlertRef?.current?.open(countryEn ? 'Incorrect username and/or password' : 'Usuario y/o contraseña incorrectos', 'error')
        }
      })

      return;
    }

    loginUser(values).then((data) => {
      if (!!data?.id && !!data?.tipo) {
        dispatch({ type: types.authLogin, payload: data });
        history.push({ pathname: "/home" });
      } else {
        AlertRef?.current?.open('Datos no encontrados', 'error')
      }
    })

  }

  return (
    <div className={classes.container}>
      <div className={classes.overlay}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.avatar}>
              <img src={countryEn ? logoEn : logo} alt="Logo" style={{ maxWidth: '90%', }} />
            </div>
            <form className={classes.form} noValidate onSubmit={send} method="post">
              <TextField
                id="standard-basic-user"
                fullWidth
                label={((store?.user?.tipo === seller) || type === seller) ? "Vendedor" : "User"}
                name={((store?.user?.tipo === seller) || type === seller) ? "vendedor" : "User"}
                className={`${classes.input} inp-login`}
                onChange={(e: any) => {
                  if (process.env.REACT_APP_USER_CORP) {
                    handleChage({ name: 'usuario', value: e.target.value })
                  } else {
                    handleChage({ name: 'ruc', value: e.target.value })
                  }

                  // handleChage({ name: 'usuario', value: e.target.value })
                }}
                value={values.usuario}
              />
              <TextField
                id="standard-basic-password"
                name="password"
                label="Password"
                fullWidth
                className={`${classes.input} inp-login`}
                onChange={(e) => handleChage({ name: 'password', value: e.target.value })}
                value={values.password}
                type="password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <strong>

                  {countryEn ? 'LOG IN' : 'Iniciar Sesión'}
                </strong>
              </Button>
              <Alert ref={AlertRef} />
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </div>
  );
};


const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${background})`,
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `#000000b8`,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    display: "flex",
  },
  input: {
    color: "#fff",
  },
  white: {
    color: "#fff",
  },
}));

